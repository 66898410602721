import React, {useState, useCallback} from 'react';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import '../../../assets/css/connect.css';
import Footer from '../../Shared/Footer';
import withUTMParams from '../../HOC/withUTMParams';
import MvpHeadingSection from './Section/MvpHeadingSection';
import GrowthPartnerSection from '../Section/GrowthPartnerSection';
import BuildTeamSection from '../Section/BuildTeamSection';
import WhyClientPreferUsSection from '../Section/WhyClientPreferUsSection';
import ClientsLogoSection from '../Section/ClientsLogoSection';
import AwardsSection from '../Section/AwardsSection';
import ClientsTestimonialSection from '../Section/ClientsTestimonialSection';
import ContactUsSection from '../Section/ContactUsSection';
import BlogPostsSection from '../Section/BlogPostsSection';
import MvpProjectsSection from './Section/MvpProjectsSection';
import ServiceInfoSection from '../Section/ServiceInfoSection';
import benefitsIcon from '../../../assets/images/connect/service-info/benefits.svg';
import investingIcon from '../../../assets/images/connect/service-info/investing.svg';

import CookieBanner from '../../Shared/CookieBanner';

const SERVICE_INFO = [
  {
    title: <h2>Test Market Demand with MVP Development</h2>,
    description:
      'Choosing the right MVP development service is essential for product validation and risk assessment. Our approach empowers companies to assess market demand and gather valuable feedback by creating an initial product version. This proactive approach identifies and addresses potential issues, leveraging the popularity of lean startup methodologies and agile practices. Experience the benefits of MVP development with us as your trusted partner.',
    imgSrc: investingIcon,
  },
  {
    title: <h3>Eliminate Technical Risks with MVP Development Solutions</h3>,
    description:
      "At Tintash, we validate product development and reduce risks through our cost-friendly MVP development services. By creating a basic version of your product, we enable you to gather valuable user feedback, refine features, and improve interactions based on insights. With expertise in agile development and over a decade's experience with start-ups, we help you bring your product to market efficiently. Partner with us to design, build, and test your customer-centric solution that resonates with your target audience.",
    imgSrc: benefitsIcon,
  },
];

function MvpConnectPageTemplate({
  location,
  metaTitle,
  metaDescription,
  pageviewTitle,
  title,
  subtitles,
  variant = 'A',
}) {
  const [, setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', {secure: true}, {expires: 150});
    setCookieState(true);
  }, []);
  React.useEffect(() => {
    ReactGA.set({
      title: variant === 'A' ? pageviewTitle : `${pageviewTitle}.B`,
    });
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://tintash.com/connect/mvp/mvp-development/" />
      </Helmet>
      <MvpHeadingSection title={title} subtitles={subtitles} variant={variant} />
      <ClientsLogoSection />
      <ServiceInfoSection serviceInfo={SERVICE_INFO} />
      <WhyClientPreferUsSection />
      <GrowthPartnerSection />
      <BuildTeamSection />
      {/* <ExpertiseServicesSection /> */}
      <MvpProjectsSection />
      <BlogPostsSection />
      <AwardsSection />
      <ClientsTestimonialSection />
      <ContactUsSection />
      {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler} />}
      <Footer showContactSection={false} showPortfolioSection={false} />
    </div>
  );
}

export default withUTMParams(MvpConnectPageTemplate);
