import React from 'react';
import ProjectCard from '../../../Shared/ProjectCard/ProjectCard';

const PROJECTS = [
  {
    id: 'life-print',
    featuredImageSrc: 'life-print.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/life-print.webp').default,
    projectDescription:
      'Print living photos on the go with Lifeprint. A social combination of AR and portable printers to share your memories all over the world.',
    projectName: 'life-print',
    categories: {
      solutions: [
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {
          value: 'News & Social Applications',
          label: 'News & Social Applications',
        },
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'Google Application Engine',
          label: 'Google Application Engine',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
      ],
    },
  },
  {
    id: 'decorist',
    featuredImageSrc: 'decorist.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/decorist.webp').default,
    projectDescription:
      'Decorist’s innovative room builder application allows designers to get photorealistic renders of designed rooms in record time.',
    projectName: 'decorist',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Unity', label: 'Unity'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
        {value: 'Jenkins', label: 'Jenkins'},
      ],
    },
  },
  {
    id: 'embr',
    featuredImageSrc: 'embr.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/embr.webp').default,
    projectDescription:
      'Embr is a heating and cooling bracelet that lets you take control & make yourself comfortable, anytime, anywhere through the connected application.',
    projectName: 'embr',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'IoT Development', label: 'IoT Development'},
        {value: 'Cloud Services', label: 'Cloud Services'},
      ],
      industries: [
        {
          value: 'Healthcare & Life Sciences',
          label: 'Healthcare & Life Sciences',
        },
        {value: 'Lifestyle', label: 'Lifestyle'},
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'Ruby on Rails', label: 'Ruby on Rails'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'IoT Software Development',
          label: 'IoT Software Development',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
        {value: 'BigQuery', label: 'BigQuery'},
      ],
    },
  },
];

function MvpProjectsSection() {
  return (
    <section className="web-dev__our-work-section">
      <div className="connect-web__expertise-heading blockchain-clients">Our Amazing Clients</div>
      <div className="web-dev__our-work-projects-container">
        {PROJECTS.map((project, i) => (
          <div key={project.id} className="web-dev__our-work-project-container">
            <ProjectCard
              {...project}
              featuredImageLoading="lazy"
              projectDescriptionClass="web-dev__our-work-project-description"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default MvpProjectsSection;
