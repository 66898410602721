import React from 'react';
import MvpConnectPageTemplate from '../../../components/Connect/Mvp';

const subtitles = {
  A: (
    <>
      From concept to execution, we develop your MVP with speed and quality, and help you get to
      market quicker.
    </>
  ),
  B: 'Leverage our expert metaverse developers to elevate your project, delivering the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <MvpConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="MVP Development Services"
      subtitles={subtitles}
      metaTitle="MVP Development Services -  Tintash"
      metaDescription="Our MVP Development Services validates your product and streamlines the development cycle. Partner with our experts in design and engineering!"
      pageviewTitle="Connect/MVP/MVP.Development"
    />
  );
}
export default index;
